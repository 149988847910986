/* eslint-disable max-len */

import { SvgIcon, SvgIconProps } from "@material-ui/core";

interface MenuButtonProps extends SvgIconProps {
  open: boolean;
}

export function MenuButton(props: MenuButtonProps): JSX.Element {
  return (
    <SvgIcon fontSize="inherit" viewBox={"0 0 31 31"} style={{ width: 26, height: 26 }} {...props}>
      {
        props.open
          ? <g fill="fff" fillRule="evenodd">
            <path
              d="M15.5 0C6.94 0 0 6.94 0 15.5 0 24.06 6.94 31 15.5 31 24.06 31 31 24.06 31 15.5 31 6.94 24.06 0 15.5 0zm0 28.886c-7.393 0-13.386-5.993-13.386-13.386 0-7.393 5.993-13.386 13.386-13.386 7.393 0 13.386 5.993 13.386 13.386 0 3.55-1.41 6.955-3.92 9.466-2.51 2.51-5.916 3.92-9.466 3.92z"
              transform="translate(-24.000000, -148.000000) translate(24.000000, 148.000000)"/>
            <path
              d="M21.165 9.807c-.413-.412-1.081-.412-1.494 0l-4.009 4.171-4.361-4.227c-.27-.275-.667-.382-1.038-.28-.371.104-.657.4-.746.775-.089.375.033.768.318 1.027l4.333 4.17-4.333 4.172c-.285.258-.407.652-.318 1.026.09.375.375.672.746.775.37.103.769-.004 1.038-.28l4.361-4.227 4.009 4.171c.259.285.652.407 1.027.318.374-.089.671-.374.774-.745.103-.371-.004-.77-.28-1.038l-4.036-4.228 4.037-4.227c.318-.399.306-.968-.028-1.353z"
              transform="translate(-24.000000, -148.000000) translate(24.000000, 148.000000)"/>
          </g>
          : <g fill="#fff" fillRule="evenodd" transform="translate(0, 10)">
            <path
              d="M24.206 7.082H1.77a1.77 1.77 0 1 0 0 3.54h22.435a1.77 1.77 0 1 0 0-3.54zM24.206 0H1.77a1.77 1.77 0 1 0 0 3.541h22.435a1.77 1.77 0 1 0 0-3.541z"
            />
          </g>
      }
    </SvgIcon>
  );
}
