import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import sectionsEnGB from "./locales/enGB/sections.json";
import uiEnGB from "./locales/enGB/ui.json";


const resources = {
  enGB: {
    sections: sectionsEnGB,
    ui: uiEnGB
  }
} as const;

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next).init({
    lng: "enGB",
    fallbackLng: "enGB",
    debug: false,
    interpolation: {
      escapeValue: false
    },
    ns: ["sections", "ui"],
    resources
  });
