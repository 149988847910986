import { Box, makeStyles, Typography } from "@material-ui/core";
import background from "../../assets/images/background.jpg";
import interiorIcon from "../../assets/images/interiorIcon.png";
import exteriorIcon from "../../assets/images/exteriorIcon.png";
import { useTranslation } from "react-i18next";
import { GameMode } from "types";
import { useAppState } from "state/state";
import { useHistory, useLocation } from "react-router-dom";
import { Intro } from "components/Intro/Intro";
import { useState } from "react";

const useStyles = makeStyles(() => ({
  page: {
    justifyContent: "center",
    backgroundSize: "cover",
    backgroundImage: `url(${background})`,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    textShadow: "0px 0px 5px #22222244",
    fontSize: "1rem"
  },
  gettingStarted: {
    width: "70%",
    height: "40%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    color: "white",
    marginTop: 90
  },
  icon: {
    position: "relative",
    maxHeight: "90%",
    maxWidth: "auto",
    marginBottom: 30,
    cursor: "pointer",
    filter: "drop-shadow(1px 1px 5px #222)",
    "&:hover": {
      filter: "brightness(1.4) drop-shadow(1px 1px 5px #555)"
    }
  },
  iconContainer: {
    position: "relative",
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center"
  }
}));

export default function GettingStarted(): JSX.Element {
  const classes = useStyles();
  const { setGameMode, setIntroComplete, introComplete } = useAppState();
  const [index, setIndex] = useState(0);
  const history = useHistory();
  const location = useLocation<{ from: Location; }>();
  const { t } = useTranslation("ui", { useSuspense: false });

  const onInteriorClicked = () => {
    setGameMode(GameMode.CABIN);
    setIntroComplete(true);
    history.replace(location?.state?.from || { pathname: "/" });
  };

  const onExteriorClicked = () => {
    setGameMode(GameMode.WALKAROUND);
    setIntroComplete(true);
    history.replace(location?.state?.from || { pathname: "/" });
  };

  if (index === 0 && !introComplete) {
    return (
      <div className={classes.page}>
        <Intro onGetStarted={() => setIndex(1)}/>
      </div>
    );
  } else {
    return (
      <div className={classes.page}>
        <Typography variant="h4" color="textSecondary">
          <Box fontWeight="fontWeightBold">
            {t("getting-started")}
          </Box>
        </Typography>
        <div className={classes.gettingStarted}>
          <div className={classes.iconContainer}>
            <img className={classes.icon} src={interiorIcon} onClick={onInteriorClicked}/>
            <Typography variant="h4">{t("interior")}</Typography>
          </div>
          <div className={classes.iconContainer}>
            <img className={classes.icon} src={exteriorIcon} onClick={onExteriorClicked}/>
            <Typography variant="h4">{t("exterior")}</Typography>
          </div>
        </div>
      </div>
    );
  }
}
