import { Redirect, Route, RouteProps, useHistory } from "react-router-dom";
import { useAppState } from "../../state/state";

export default function PrivateRoute({ children }: RouteProps) {
  const { isAuthReady, user } = useAppState();
  const navigate = useHistory();

  const renderChildren = user;
  if (!renderChildren && !isAuthReady) {
    navigate.push("/login");
  }

  return (
    <Route
      render={({ location }) =>
        renderChildren ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}
