export type Callback = (...args: unknown[]) => void;
export type ErrorCallback = (error: null | Error) => void;
export type FlagSvg = () => JSX.Element
export interface IRequestError { status: number, text: string }

export enum SceneType {
  UNITY_SCENE = "unityScene",
  UNITY_360 = "unity360",
  REACT = "react"
}

export enum GameMode {
  IDLE,
  CABIN,
  WALKAROUND
}

export type Stage  = {
  titleID: string;
}

export type Section = {
  index: number;
  section: number;
  subSection: number;
  titleID: string;
  sceneType: SceneType;
  sceneName: string;
  hide: boolean;
  _id?: string;
}

export type PasscodeAuth = {
  passcode: string;
}
