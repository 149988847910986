// Unity events
export const UNITY = {
  NEXT_SECTION: "OnNextSubSection",
  SECTION_CHANGED: "OnSectionChange",
  LOADED: "loaded",
  XR_READY: "OnXRReady",
  LISTENER: "JavascriptListener",
  PLAY_VIDEO: "OnPlayReactVideo",
  SET_STAGE: "OnSetStage",
  SET_SECTION: "OnSetSection",
  NAVIGATE: "OnNavigateEvent",
  HOVER: "OnHoverInteractable",
  END_HOVER: "OnEndHoverInteractable"
};

// App Events
export const EVENTS = {
  UNLOAD: "unload",
  NAVIGATE_FORWARD: "OnNavigateForward",
  NAVIGATE_BACK: "OnNavigateBack",
  CANVAS_CLICKED: "canvasClicked"
};

