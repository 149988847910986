import { SvgIcon, SvgIconProps } from "@material-ui/core";

export default function Circle(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 24, height: 24 }} {...props}>
      <g fill="none" fillRule="evenodd">
        <g stroke="#FFF" strokeWidth="1.492">
          <g>
            <g transform="translate(-24.000000, -285.000000)
translate(0.000000, 188.000000) translate(25.000000, 98.000000)">
              <circle cx="11" cy="11" r="11"/>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
