import "./i18n/i18next-config";
import { Suspense, useEffect } from "react";
import { Header } from "./components/Header/Header";
import { Loader } from "./pages/Loader/Loader";
import { XRContainer } from "./components/XRContainer/XRContainer";
import { useAppState } from "./state/state";
import useNavigationContext from "./hooks/useNavigationContext/useNavigationContext";

const App = (): JSX.Element => {
  const { getSections, isFetching, gameMode } = useAppState();
  const { currentSection } = useNavigationContext();

  useEffect(() => {
    getSections();
  }, [gameMode]);

  return (
    <Suspense fallback={<Loader />}>
        {!isFetching && <XRContainer />}
        {(isFetching || currentSection === null)
          ? <Loader />
          : <Header />
        }
    </Suspense>
  );
};

export default App;
