import { EVENTS, UNITY } from "../../constants";
import { Theme, makeStyles } from "@material-ui/core";
import { Unity, useUnityContext } from "react-unity-webgl";
import { useEffect, useState } from "react";
import { SceneType } from "../../types";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import { useAppState } from "../../state/state";
import useNavigationContext from "../../hooks/useNavigationContext/useNavigationContext";
import { useHistory } from "react-router-dom";

type XRContainerStyleProps = {
  isInteractive: boolean,
  isOpen: boolean;
};

const useStyles = makeStyles<Theme, XRContainerStyleProps>((theme: Theme) => ({
  root: {
    userSelect: "none",
    height: (props: XRContainerStyleProps) => props.isOpen ? "100%" : 0,
    width: (props: XRContainerStyleProps) => props.isOpen ? "100%" : 0,
    paddingTop: (props: XRContainerStyleProps) => props.isOpen
      ? theme.headerHeight
      : 0,
    pointerEvents: (props: XRContainerStyleProps) => props.isInteractive ? "auto" : "none"
  }
}));

export const XRContainer = (): JSX.Element => {
  const {
    isXRReady,
    setXRReady,
    allowInteraction,
    setAllowInteraction,
    videoSrc,
    setVideoSrc,
    setUnityLoadProgress
  } = useAppState();
  const {
    nextSection,
    currentSection,
    setCurrentStage,
    navigationEvents,
    setSectionByIndex
  } = useNavigationContext();
  const classes = useStyles({
    isInteractive: allowInteraction,
    isOpen: currentSection === null ? false : currentSection.sceneType === SceneType.UNITY_SCENE
  });
  const [isLoaded, setIsLoaded] = useState(false);
  const [hoveringInteractable, setHoveringInteractable] = useState(false);
  const history = useHistory();
  const { unityProvider,
    sendMessage,
    addEventListener,
    removeEventListener,
    unload,
    loadingProgression } = useUnityContext({
    loaderUrl: "unity/App.loader.js",
    dataUrl: "unity/App.data.unityweb",
    frameworkUrl: "unity/App.framework.js.unityweb",
    codeUrl: "unity/App.wasm.unityweb"
  });

  const onUpdateSection = () => {
    if (currentSection !== null) {
      sendMessage(UNITY.LISTENER, UNITY.SECTION_CHANGED, JSON.stringify(currentSection));
    }
  };

  const onHoverInteractable = () => {
    setHoveringInteractable(true);
  };

  const onEndHoverInteractable = () => {
    setHoveringInteractable(false);
  };

  const onNextSection = () => {
    nextSection();
  };

  const onCanvasClicked = () => {
    navigationEvents.emit(EVENTS.CANVAS_CLICKED);
  };

  const onPlayVideo = (url: string) => {
    setAllowInteraction(false);
    setVideoSrc(url);
  };

  const onClosePlayer = () => {
    setAllowInteraction(true);
    setVideoSrc(null);
  };

  useEffect(() => {
    onUpdateSection();
  }, [isLoaded, currentSection]);

  async function handleClickBack() {
    await unload();
    setUnityLoadProgress(0);
    setXRReady(false);
    history.push("/getting-started");
  }

  // Register navigation events
  useEffect(() => {
    addEventListener(UNITY.NEXT_SECTION, onNextSection);
    navigationEvents.on(EVENTS.UNLOAD, handleClickBack);
    return function cleanup() {
      navigationEvents.removeAllListeners();
    };
  }, [navigationEvents]);

  useEffect(() => {
    setUnityLoadProgress(loadingProgression);
  }, [loadingProgression]);

  // Register event listeners
  useEffect(function () {
    addEventListener(UNITY.LOADED, () => setIsLoaded(true));
    addEventListener(UNITY.XR_READY, () => setXRReady(true));
    addEventListener(UNITY.PLAY_VIDEO, (url) => onPlayVideo(url));
    addEventListener(UNITY.SET_STAGE, (id) => setCurrentStage(id));
    addEventListener(UNITY.SET_SECTION, (id) => setSectionByIndex(id));
    addEventListener(UNITY.HOVER, () => onHoverInteractable());
    addEventListener(UNITY.END_HOVER, () => onEndHoverInteractable());

    return function cleanup() {
      removeEventListener(UNITY.LOADED, setIsLoaded);
      removeEventListener(UNITY.XR_READY, setXRReady);
      removeEventListener(UNITY.PLAY_VIDEO, onPlayVideo);
      removeEventListener(UNITY.SET_STAGE, setCurrentStage);
      removeEventListener(UNITY.SET_SECTION, setSectionByIndex);
      removeEventListener(UNITY.HOVER, onHoverInteractable);
      removeEventListener(UNITY.END_HOVER, onEndHoverInteractable);
    };
  }, [isLoaded]);

  const display: string = (currentSection && isXRReady)
    ? (currentSection.sceneType === SceneType.UNITY_SCENE)
      ? "block"
      : "none"
    : "none";

  return (
    <div className={classes.root} onClick={() => onCanvasClicked()}>
      {videoSrc ? <VideoPlayer src={videoSrc} closePlayer={onClosePlayer} /> : null}
      <Unity
        unityProvider={unityProvider}
        style={{
          display: display,
          height: "100%",
          width: "100%",
          cursor: hoveringInteractable ? "pointer" : "default"
        }} />
    </div>);
};
