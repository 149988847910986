import { Button, Theme, Typography, makeStyles } from "@material-ui/core";

import { MenuButton } from "../Icons/MenuButton";
import { useTranslation } from "react-i18next";
import { Section } from "types";

const useStyles = makeStyles((theme: Theme) => ({
  bottomContainer: {
    display: "flex",
    backgroundColor: "#058A91",
    height: theme.subHeaderHeight,
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.paddingSidesS
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.paddingSidesM
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: theme.paddingSidesL
    },
    alignItems: "center",
    zIndex: -1,
    fontSize: "14px"
  },
  menuButton: {
    padding: 4
  },
  sectionNameText: {
    paddingLeft: 5,
    fontWeight: 700
  }
}));

interface SectionNameProps {
  section: Section;
  open: boolean;
  toggleMenu: () => void;
}

export const SectionName = ({ section, open, toggleMenu }: SectionNameProps):
  JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation("sections");
  const makeName = (sectionName: boolean): string => {
    if (sectionName) {
      return (
        " > " +
        " " +
        t("section-title." + section?.titleID));
    } else {
      return (
        "" +
        t("section-group-title." + section?.section) + " ");
    }
  };

  return (
    <div className={classes.bottomContainer}>
      <Button
        className={classes.menuButton}
        onClick={toggleMenu}
        color="primary"
        size="large"
        startIcon={<MenuButton open={!open} />}>
        <Typography variant="body1" color="textPrimary">
          {open ? makeName(false) : t("general.close_menu")}
        </Typography>
        {
          open && <Typography className={classes.sectionNameText} variant="body1" color="textPrimary">
            {makeName(true)}
          </Typography>
        }
      </Button>
    </div>
  );
};
