import AppStateProvider from "./state/state";
import {
  Route,
  BrowserRouter,
  Switch
} from "react-router-dom";
import App from "./App";
import { CssBaseline } from "@material-ui/core";
import LoginPage from "./pages/LoginPage/LoginPage";
import NavigationProvider from "./components/NavigationProvider/NavigationProvider";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import GettingStarted from "pages/GettingStarted/GettingStarted";

const XRApp = () => {
  return (
    <>
      <NavigationProvider>
        <App />
      </NavigationProvider>
    </>
  );
};

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <React.StrictMode>
      <BrowserRouter>
        <AppStateProvider>
          <Switch>
            <PrivateRoute exact path="/">
              <XRApp />
            </PrivateRoute>
            <Route path="/login">
              <LoginPage />
            </Route>
            <PrivateRoute path="/getting-started">
              <GettingStarted />
            </PrivateRoute>
          </Switch>
        </AppStateProvider>
      </BrowserRouter>
    </React.StrictMode>
  </ThemeProvider>,
  document.getElementById("root")
);
