import { Link, Theme, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import logo from "../../assets/images/CMETB_Logo2.png";
import { EVENTS } from "../../constants";
import { ModuleMenu } from "../ModuleMenu/ModuleMenu";
import { SectionName } from "./SectionName";
import { useAppState } from "../../state/state";
import useNavigationContext from "../../hooks/useNavigationContext/useNavigationContext";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  outter: {
    padding: 0,
    position: "fixed",
    width: "100%",
    top: 0,
    zIndex: 2
  },
  logoContainer: {
    display: "flex",
    backgroundColor: "white",
    height: theme.headerHeight,
    zIndex: 4,
    flexDirection: "row-reverse",
    alignItems: "center",
    alignContent: "flex-start",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    paddingLeft: theme.paddingSidesL,
    paddingRight: theme.paddingSidesL
  },
  logo: {
    position: "absolute",
    left: "50px",
    top: "10px"
  },
  linkContainerOutter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    minWidth: 310,
    maxWidth: 500,
    padding: 0,
    textAlign: "right"
  },
  linkContainer: {
    cursor: "pointer",
    textAlign: "right",
    color: "#000",
    fontSize: "20px",
    textDecoration: "none",
    paddingLeft: "16px",
    fontWeight: 400,
    "&:hover": {
      color: "#777"
    }
  }
}));

const Header = (): JSX.Element => {
  const classes = useStyles();
  const { signOut } = useAppState();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const toggleMenu = () => setMenuOpen(!menuOpen);
  const { currentSection, navigationEvents } = useNavigationContext();
  const { t } = useTranslation("ui");

  const onSignOutClicked = () => {
    signOut?.();
  };

  const onBackClicked = () => {
    navigationEvents.emit(EVENTS.UNLOAD);
  };

  useEffect(() => {
    navigationEvents.on(EVENTS.CANVAS_CLICKED, () => {
      setMenuOpen(false);
    });
    return function cleanup() {
      navigationEvents.removeAllListeners();
    };
  }, [navigationEvents]);

  return (
    <div className={classes.outter}>
      <div className={classes.logoContainer}>
        <div className={classes.logo}>
          <img src={logo} />
        </div>
        <div className={classes.linkContainerOutter}>
          <Link className={classes.linkContainer} href="#" onClick={onSignOutClicked}>
            {t("signout")}
          </Link>
          <Link className={classes.linkContainer} onClick={onBackClicked}>
            {t("back")}
          </Link>
        </div>
      </div>
      <ModuleMenu open={menuOpen} />
      <SectionName
        toggleMenu={toggleMenu}
        section={currentSection}
        open={!menuOpen}
      />
    </div>);
};

export { Header };
