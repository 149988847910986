/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { appPasscode } from "data/passcodes";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

export function getPasscode() {
  const match = window.location.search.match(/passcode=(.*)&?/);
  const passcode = match ? match[1] : window.sessionStorage.getItem("passcode");
  return passcode;
}

export function verifyPasscode(passcode: string) {
  return fetch("/getting-started", {
    method: "GET",
    headers: { "content-type": "application/json" }
  })
    .then(
      async _res => {
        if (passcode === appPasscode) {
          return { isValid: true };
        }
        return { isValid: false };
      }
    );
}

export default function usePasscodeAuth() {
  const history = useHistory();

  const [user, setUser] = useState<{ id: undefined, passcode: string } | null>(null);
  const [isAuthReady, setIsAuthReady] = useState(false);

  useEffect(() => {
    const passcode = getPasscode();

    if (passcode) {
      verifyPasscode(passcode)
        .then(verification => {
          if (verification?.isValid) {
            setUser({ passcode } as any);
            window.sessionStorage.setItem("passcode", passcode);
            history.replace(window.location.pathname);
          }
        })
        .then(() => setIsAuthReady(true));
    } else {
      setIsAuthReady(true);
    }
  }, [history]);

  const signIn = useCallback((passcode: string) => {
    return verifyPasscode(passcode).then(verification => {
      if (verification?.isValid) {
        setUser({ passcode } as any);
        window.sessionStorage.setItem("passcode", passcode);
      } else {
        throw new Error("Invalid passcode");
      }
    });
  }, []);

  const signOut = useCallback(() => {
    setUser(null);
    window.sessionStorage.removeItem("passcode");
    return Promise.resolve();
  }, []);

  return { user, isAuthReady, signIn, signOut };
}
