/* eslint-disable @typescript-eslint/no-extra-non-null-assertion */

import React, { createContext, useContext, useState } from "react";
import { EventEmitter } from "events";
import usePasscodeAuth from "./usePasscodeAuth/usePasscodeAuth";
import { GameMode, Section } from "types";
import { appSectionsInterior, appSectionsExterior } from "../data/sections";

export interface StateContextType {
  error: Error | null;
  setError(error: Error | null): void;
  getSections(): Promise<Section[]>;
  isFetching: boolean;
  isAuthReady?: boolean;
  introComplete: boolean;
  setIntroComplete(complete: boolean): void;
  user?: { id: undefined, passcode: string; } | null;
  signIn?(passcode: string, email: string): Promise<void>;
  signOut?(): void;
  isXRReady: boolean;
  setXRReady(ready: boolean): void;
  allowInteraction: boolean;
  setAllowInteraction(allow: boolean): void;
  sections: Section[];
  appEvents: EventEmitter;
  videoSrc: string | null;
  setVideoSrc(src: string | null): void;
  gameMode: GameMode;
  setGameMode(gm: GameMode): void;
  unityLoadProgress: number;
  setUnityLoadProgress(progress: number): void;
}

export const StateContext = createContext<StateContextType>(null!!);

export default function AppStateProvider(props: React.PropsWithChildren<Record<string, unknown>>) {
  const [error, setError] = useState<Error | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [sections, setSections] = useState<Section[]>();
  const [isXRReady, setIsXRReady] = useState(false);
  const [allowInteraction, setAllowInteraction] = useState(true);
  const appEvents = new EventEmitter();
  const [videoSrc, setVideoSrc] = useState<string | null>(null);
  const [gameMode, setGameMode] = useState<GameMode>(GameMode.IDLE);
  const [introComplete, setIntroComplete] = useState(false);

  const [unityLoadProgress, setUnityLoadProgress] = useState(0);

  let contextValue = {
    isFetching,
    error,
    setError,
    isXRReady,
    sections,
    appEvents,
    videoSrc,
    setVideoSrc,
    introComplete,
    setIntroComplete,
    gameMode,
    setGameMode,
    unityLoadProgress,
    setUnityLoadProgress,
    allowInteraction
  } as StateContextType;

  contextValue = {
    ...contextValue,
    ...usePasscodeAuth(),

    getSections: async () => {
      const endpoint = "/";
      return fetch(endpoint, {
        method: "GET"
      }).then(async res => {
        const jsonResponse = await res.json();
        return jsonResponse.data;
      })
        .catch(err => setError(err));
    },
    setXRReady: (ready: boolean) => {
      setIsXRReady(ready);
    },
    setAllowInteraction: (allow: boolean) => {
      setAllowInteraction(allow);
    }
  };

  const getSections: StateContextType["getSections"] = () => {
    setIsFetching(true);
    return contextValue
      .getSections()
      .then(async (res) => {
        setSections(gameMode === GameMode.CABIN ? appSectionsInterior : appSectionsExterior);
        setIsFetching(false);
        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  return (
    <StateContext.Provider
      value={{
        ...contextValue,
        getSections
      }}
    >
      {props.children}
    </StateContext.Provider>
  );
}

export function useAppState(): StateContextType {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useAppState must be used with the AppStateProvider");
  }
  return context;
}
