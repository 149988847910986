import { EVENTS } from "../../constants";
import React, { createContext, useState } from "react";
import { EventEmitter } from "events";
import { useAppState } from "../../state/state";
import { Section } from "types";

type NavigationContextType = {
  nextSection(): void;
  previousSection(): void;
  canNavigateBack(): boolean;
  canNavigateForward(): boolean;
  setSection(section: Section | null): void;
  setSectionByIndex(index: number): void;
  setCurrentStage(stage: number): void;
  goToStart(): void;
  currentSection: Section;
  currentStage: number;
  navigationEvents: EventEmitter;
};

export const NavigationContext = createContext<NavigationContextType>(null!);

const NavigationProvider = (props: React.PropsWithChildren<Record<string, unknown>>) => {
  const { sections } = useAppState();
  const [currentStage, setCurrentStage] = useState(0);
  const [currentSection, setCurrentSection] = useState<Section>(null!);
  const navigationEvents = new EventEmitter();

  const setSection = async (section: Section) => {
    setCurrentStage(0);
    setCurrentSection(section);
  };

  const nextSection = () => {
      navigationEvents.emit(EVENTS.NAVIGATE_FORWARD, currentStage + 1);
      const currentIndex = sections!.indexOf(currentSection!);
      const newSection = sections![currentIndex + 1];
      if (newSection) {
        setSection(newSection);
      }
  };

  const previousSection = () => {
      navigationEvents.emit(EVENTS.NAVIGATE_BACK, currentStage - 1);
      const currentIndex = sections!.indexOf(currentSection!);
      const newSection = sections![currentIndex - 1];
      if (newSection) {
        setSection(newSection);
      }
  };

  const setSectionByIndex = (_index: number) => {
    const s = sections?.find(({ index }) => index === _index);
    if (s) {
      setSection(s);
    }
  };

  const goToStart = () => {
    setCurrentStage(0);
    setCurrentSection(sections![0]);
  };

 const canNavigateBack = () => {
    if (currentSection.index === 1) {
      return false;
    }
   return true;
  };

  const canNavigateForward = () => {
    const curIndex = sections!.indexOf(currentSection);
    if (curIndex >= sections!.length - 1) {
      return false;
    }

    return true;
  };

  return (
    <NavigationContext.Provider
      value={{
        nextSection,
        previousSection,
        canNavigateBack,
        canNavigateForward,
        setSection,
        setSectionByIndex,
        setCurrentStage,
        goToStart,
        currentSection,
        navigationEvents,
        currentStage
      }}
    >
      {props.children}
    </NavigationContext.Provider>
  );
};

export default NavigationProvider;
