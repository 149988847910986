import { Button, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import ReactPlayer from "react-player/vimeo";

interface IntroProps {
  onGetStarted(): void;
}

const useStyles = makeStyles(() => ({
  outer: {
    position: "absolute",
    top: 190,
    bottom: 0,
    width: "100%",
    alignContent: "center",
    userSelect: "none",
    pointerEvents: "none"
  },
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 4,
    width: "640px",
    height: "auto",
    margin: "auto",
    textAlign: "center",
    pointerEvents: "all"
  },
  title: {
    fontWeight: 700,
    fontSize: 60,
    textShadow: "2px 2px 5px black"
  },
  startButton: {
    width: 215,
    marginTop: 20,
    height: 48,
    borderRadius: 40,
    color: "#000",
    fontSize: 14,
    fontWeight: 700,
    marginBottom: 30
  },
  subtitle: {
    fontWeight: 400,
    fontSize: 20,
    textShadow: "3px 3px 5px black",
    marginBottom: 30
  },
  video: {
    backgroundColor: "#fff",
    width: 650,
    height: 330,
    borderStyle: "solid",
    borderWidth: "4px",
    borderRadius: "4px"
  }
}));

const Intro = (props: IntroProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation("ui");

  const onButtonClick = () => {
    props.onGetStarted();
  };

  return (
    <>
        <div className={classes.outer}>
          <div className={classes.container}>
            <Typography className={classes.title}>{t("title")}</Typography>
            <Button
              className={classes.startButton}
              variant="contained"
              color="secondary"
              type="submit"
              onClick={onButtonClick}
            >
              {t("get_started")}
            </Button>
            <Typography className={classes.subtitle}>
              {t("intro")}
            </Typography>
            <div className={classes.video}>
              <ReactPlayer
                controls={true}
                className='reactPlayer'
                url={"https://vimeo.com/763826555"}
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </div>
    </>
  );
};

export { Intro };
