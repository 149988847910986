import { SceneType, Section } from "../types";

export const appSectionsInterior: Section[] = [
  { /* ----------------------- Section 1 ----------------------- */
    index: 0,
    section: 1,
    subSection: 1,
    titleID: "3_Interior",
    sceneType: SceneType.UNITY_SCENE,
    sceneName: "3_Interior",
    hide: false
  }
];

export const appSectionsExterior: Section[] = [
  {
    index: 1,
    section: 2,
    subSection: 1,
    titleID: "4_Front",
    sceneType: SceneType.UNITY_SCENE,
    sceneName: "4_Front",
    hide: false
  },
  {
    index: 2,
    section: 2,
    subSection: 2,
    titleID: "5_Side",
    sceneType: SceneType.UNITY_SCENE,
    sceneName: "5_Side",
    hide: false
  },
  {
    index: 3,
    section: 2,
    subSection: 3,
    titleID: "6_Back",
    sceneType: SceneType.UNITY_SCENE,
    sceneName: "6_Back",
    hide: false
  }
];
