import { ChangeEvent, FormEvent, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { OcadoInputField } from "../../components/OcadoInputField/OcadoInputField";
import Typography from "@material-ui/core/Typography";
import alert from "../../assets/images/alert.png";
import cloud from "../../assets/images/cloud.png";
import globe from "../../assets/images/globe.png";
import laptop from "../../assets/images/laptop.png";
import logo from "../../assets/images/CMETB_Logo.png";
import { useAppState } from "../../state/state";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  errorMessage: {
    color: "red",
    display: "flex",
    alignItems: "center",
    margin: "1em 0 0.2em",
    "& svg": {
      marginRight: "0.4em"
    }
  },
  cmetbLogo: {
    position: "absolute",
    left: 75,
    top: 75
  },
  loginContainer: {
    backgroundColor: "#058A91",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100vh",
    zIndex: 3,
    paddingTop: 170
  },
  passcodeContainer: {
    width: 347,
    marginTop: 25,
    marginBottom: 45,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center"
  },
  textBox: {
    width: 410,
    marginTop: 20,
    marginBottom: 20,
    display: "flex"
  },
  icon: {
    marginRight: 17,
    maxWidth: 43,
    height: "100%",
    objectFit: "contain"
  },
  noteText: {
    color: "#f4f4f4",
    fontWeight: 400,
    fontSize: 14
  },
  loginButton: {
    width: 155,
    marginTop: 20,
    height: 48,
    borderRadius: 40,
    color: "#000",
    fontSize: 14
  },
  titleText: {
    fontWeight: 700,
    fontSize: 60
  }
}));

export default function LoginPage() {
  const classes = useStyles();
  const { signIn, user } = useAppState();
  const history = useHistory();
  const location = useLocation<{ from: Location; }>();
  const [passcode, setPasscode] = useState("");
  const [authError, setAuthError] = useState<Error | null>(null);
  const { t } = useTranslation("ui", { useSuspense: false });

  const login = () => {
    setAuthError(null);
    signIn?.(passcode, "")
      .then(() => {
        history.push("/getting-started");
      })
      .catch((err) => setAuthError(err));
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    login();
  };

  if (user) {
    history.replace(location?.state?.from || { pathname: "/getting-started" });
  }

  return (
    <div className={classes.loginContainer}>
      <div className={classes.cmetbLogo}>
        <img src={logo} />
      </div>
      <Typography className={classes.titleText}>{t("title")}</Typography>
      <form onSubmit={handleSubmit}>
        <div className={classes.passcodeContainer}>
          <OcadoInputField
            fullWidth
            id="input-email"
            onChange={(e: ChangeEvent<HTMLInputElement>) => setPasscode(e.target.value)}
            placeholder={t("passcode")}
            autoComplete="current-email"
            type="password"
            required
          />
          <Button
            className={classes.loginButton}
            disableElevation
            variant="contained"
            color="secondary"
            type="submit"
          >
            {t("login")}
          </Button>
          <div>
          </div>
          {authError && (
            <Typography variant="caption" className={classes.errorMessage}>
              <ErrorOutlineIcon />
              {authError.message}
            </Typography>
          )}
        </div>
      </form>
      <div className={classes.textBox}>
        <img className={classes.icon} src={alert} />
        <Typography className={classes.noteText}>{t("login_note_a")}
        </Typography>
      </div>
      <div className={classes.textBox}>
        <img className={classes.icon} src={laptop} />
        <Typography className={classes.noteText}>{t("login_note_b")}
        </Typography>
      </div>
      <div className={classes.textBox}>
        <img className={classes.icon} src={globe} />
        <Typography className={classes.noteText}>{t("login_note_c")}
        </Typography>
      </div>
      <div className={classes.textBox}>
        <img className={classes.icon} src={cloud} />
        <Typography className={classes.noteText}>{t("login_note_d")}
        </Typography>
      </div>
    </div>);
}
